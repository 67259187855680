import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import './styles/index.scss';
import { Container } from "react-bootstrap";
import { StructuredText } from "react-datocms";

const PrivacyPolicy = ({ data }) => {
  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common technology">
        <h1>{data.datoCmsInformativePage.structPrivacyPolicies[0].title}</h1>
        <StructuredText data={data.datoCmsInformativePage.structPrivacyPolicies[0].description.value} />
      </Container>
    </div>
  )
}

export default PrivacyPolicy

export const Head = ({ data }) => (
  <Seo
    title="Privacy Policy"
    description="Collect • Play • Trade"
    image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
  />
)

export const data = graphql`
query PrivacyPolicy {
    datoCmsInformativePage {
      structPrivacyPolicies {
        id
        title
        description {
          value
        }
      }
    }
  }
`
